<template>
    <div class="wrap">
        <div class="container">
            <div class="nav_bar">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/recruit' }">我的招聘</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ $route.query.edit ? '修改招聘信息' : '填写招聘信息'}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="form_area">
                <el-form :model="recruitInfo" :rules="rules" ref="recruitInfo" label-width="120px" class="form">
                    <el-form-item label="岗位名称" prop="jobName">
                        <el-input v-model="recruitInfo.jobName" placeholder="请填写岗位名称"></el-input>
                    </el-form-item>
                    <el-form-item label="薪资待遇" prop="salary">
                        <el-input v-model="recruitInfo.salary" placeholder="请填写薪资待遇"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位要求或福利" prop="requirements">
                        <div class="tags_sty" :class="{'focus':tagActive}">
                            <span class="tags_item_sty" v-for="(i, index) in tagsList" :key="index">
                                {{ i }}
                                <i class="el-icon-close" @click="deleteTag(index)"></i>
                            </span>
                            <input type="text" placeholder="按回车键Enter创建标签" @focus="tagActive = true"
                                @blur="tagActive = false" v-model="tagName" @keyup.enter="addTag" />
                            <span class="tips">还可以添加{{ surplusNum }}个标签</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="岗位描述" prop="jobInfo">
                        <el-input type="textarea" v-model="recruitInfo.jobInfo" resize="none" :autosize="{ minRows: 3 }"
                            placeholder="请填写岗位描述"></el-input>
                    </el-form-item>
                    <el-form-item label="公司介绍" prop="companyInfo">
                        <el-input type="textarea" v-model="recruitInfo.companyInfo" resize="none"
                            :autosize="{ minRows: 3 }" placeholder="请填写公司介绍"></el-input>
                    </el-form-item>
                    <el-form-item label="工作地点" prop="jobAddress">
                        <el-input type="textarea" v-model="recruitInfo.jobAddress" resize="none"
                            :autosize="{ minRows: 3 }" placeholder="请填写工作地点"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="contactUs">
                        <el-input type="textarea" v-model="recruitInfo.contactUs" resize="none"
                            :autosize="{ minRows: 3 }" placeholder="请填写联系方式"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="submit_btn" type="primary" @click="submitForm('recruitInfo')">提交</el-button>
                        <el-button class="reset_btn" @click="resetForm('recruitInfo')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>
    </div>
</template>

<script>
    import {
        releaseRecruit,
        editRecruit,
        getJobInfo
    } from '@/api/recruit'
    export default {
        name: 'releaseRecruit',
        data() {
            return {
                recruitInfo: {
                    jobName: '',
                    salary: '',
                    requirements: '',
                    jobInfo: '',
                    companyInfo: '',
                    jobAddress: '',
                    contactUs: ''
                },
                rules: {
                    jobName: {
                        required: true,
                        message: "请填写岗位名称",
                        trigger: "blur",
                    },
                    salary: {
                        required: true,
                        message: "请填写薪资待遇",
                        trigger: "blur",
                    },
                    jobInfo: {
                        required: true,
                        message: "请填写岗位描述",
                        trigger: "blur",
                    },
                    companyInfo: {
                        required: true,
                        message: "请填写公司介绍",
                        trigger: "blur",
                    },
                    jobAddress: {
                        required: true,
                        message: "请填写工作地点",
                        trigger: "blur",
                    },
                    contactUs: {
                        required: true,
                        message: "请填写联系方式",
                        trigger: "blur",
                    }
                },
                tagActive: false,
                tagsList: [],
                tagName: ''
            }
        },
        created() {
            if (this.$route.query.edit) this.getInfo();
        },
        computed: {
            surplusNum() {
                return 5 - this.tagsList.length;
            },
        },
        methods: {
            // 添加tag
            addTag() {
                if (this.tagName.length > 10) {
                    this.$message({
                        type: "warning",
                        message: "标签最多10个字",
                    });
                    return;
                }
                if (this.tagsList.length > 4) {
                    this.$message({
                        type: "warning",
                        message: "最多只能添加五个标签",
                    });
                    return;
                }
                if (this.tagName.trim() !== "") {
                    this.tagsList.push(this.tagName);
                }
                this.recruitInfo.requirements = JSON.stringify(this.tagsList);
                this.tagName = "";
            },
            // 删除tag
            deleteTag(index) {
                this.tagsList.splice(index, 1);
                this.recruitInfo.requirements = JSON.stringify(this.tagsList);
            },
            // 提交
            submitForm(formName) {
                console.log(this.recruitInfo);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log('submit!')
                        if (this.$route.query.edit) {
                            editRecruit(this.recruitInfo).then(res => {
                                if (res.code == 200) {
                                    this.$message.success('发布成功');
                                    setTimeout(() => {
                                        this.$router.go(-1);
                                    }, 500);
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        } else {
                            releaseRecruit(this.recruitInfo).then(res => {
                                if (res.code == 200) {
                                    this.$message.success('发布成功');
                                    setTimeout(() => {
                                        this.$router.go(-1);
                                    }, 500);
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })

                        }
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.tagsList = [];
                this.tagName = '';
            },
            // 获取招聘信息
            getInfo() {
                this.$loading.show();
                getJobInfo(this.$route.query.jobId).then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.tagsList = JSON.parse(res.data.requirements);
                        this.recruitInfo = res.data;
                    } else {
                        console.log(res.msg)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .wrap {
        min-height: 693px;
        background: #f3f3f3;

        .nav_bar {
            padding: 30px;
            margin: 30px 0;
            background-color: #fff;
            border-radius: 4px;
        }

        .form_area {
            margin: 0 30px;
            padding: 70px 280px 100px;
            border-radius: 4px;
            background-color: #fff;

            ::v-deep.form {
                .tags_sty {
                    padding: 0 15px;
                    width: 100%;
                    min-height: 38px;
                    position: relative;
                    border: 1px solid #DCDFE6;
                    border-radius: 4px;
                    text-align: left;
                    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);

                    &.focus {
                        border-color: #409EFF !important;
                    }

                    &:hover {
                        border-color: #C0C4CC;
                    }

                    input {
                        height: 38px;
                        display: inline-block;
                        vertical-align: top;

                        &::placeholder {
                            color: #C0C4CC;
                        }
                    }

                    .tags_item_sty {
                        display: inline-block;
                        background: #0e7aff;
                        color: #fff;
                        padding: 0 5px;
                        height: 28px;
                        line-height: 28px;
                        margin-right: 4px;
                        position: relative;

                        .el-icon-close {
                            margin-left: 3px;
                            cursor: pointer;
                        }
                    }

                    .tips {
                        position: absolute;
                        top: 50%;
                        right: -130px;
                        transform: translateY(-50%);
                        color: #C0C4CC;
                    }
                }

                .el-input__inner,
                .el-textarea__inner {
                    font-family: inherit;
                }

                .submit_btn,
                .reset_btn {
                    width: 97px;
                    height: 40px;

                    &.submit_btn {
                        background: #1890ff;
                        border-color: #1890ff;
                    }
                }
            }
        }
    }
</style>